{
  "name": "lector-frontend",
  "description": "The lector.ai web frontend.",
  "version": "10.17.4",
  "scripts": {
    "ng": "ng",
    "start": "ng s -o --port 4200",
    "debug": "ng s -o --port 4200 --host=127.0.0.1",
    "build": "npm run ng-high-memory build --localize",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "ng-high-memory": "node --max_old_space_size=8000 ./node_modules/@angular/cli/bin/ng",
    "hmr": "ng s -o --hmr --verbose",
    "analyze": "npm run ng-high-memory build -- --source-map",
    "analyze:view": "source-map-explorer dist/**/*.js",
    "test-coverage": "ng test --code-coverage --watch=false",
    "color-less": "ng-alain-plugin-theme -t=colorLess",
    "theme": "ng-alain-plugin-theme -t=themeCss",
    "icon": "ng g ng-alain:plugin icon",
    "lint": "npm run lint:ts && npm run lint:style",
    "lint:ts": "ng lint --cache",
    "lint:style": "npx stylelint \"src/**/*.less\"",
    "lint-fix": "npm run lint:ts -- --fix && npm run lint:style -- --fix",
    "codegen-data": "NODE_OPTIONS='--dns-result-order=ipv4first' DOTENV_CONFIG_PATH=.env graphql-codegen -r dotenv/config --config src/app/graphql/codegen-data.yml",
    "codegen-frontend-data": "NODE_OPTIONS='--dns-result-order=ipv4first' DOTENV_CONFIG_PATH=.env graphql-codegen -r dotenv/config --config src/app/graphql/codegen-frontend-data.yml",
    "codegen-history": "NODE_OPTIONS='--dns-result-order=ipv4first' DOTENV_CONFIG_PATH=.env graphql-codegen -r dotenv/config --config src/app/graphql/codegen-history.yml",
    "storybook": "ng run frontend:storybook",
    "build-storybook": "ng run frontend:build-storybook"
  },
  "private": true,
  "engines": {
    "npm": "^10.2.4",
    "node": "^20.11.1"
  },
  "dependencies": {
    "@angular/animations": "^18.1.0",
    "@angular/cdk": "^18.1.0",
    "@angular/common": "^18.1.0",
    "@angular/compiler": "^18.1.0",
    "@angular/core": "^18.1.0",
    "@angular/forms": "^18.1.0",
    "@angular/platform-browser": "^18.1.0",
    "@angular/platform-browser-dynamic": "^18.1.0",
    "@angular/router": "^18.1.0",
    "@angular/service-worker": "^18.1.0",
    "@delon/abc": "^18.0.2",
    "@delon/acl": "^18.0.2",
    "@delon/auth": "^18.0.2",
    "@delon/cache": "^18.0.2",
    "@delon/chart": "^18.0.2",
    "@delon/form": "^18.0.2",
    "@delon/mock": "^18.0.2",
    "@delon/theme": "^18.0.2",
    "@delon/util": "^18.0.2",
    "@fontsource/fira-mono": "^5.0.13",
    "@graphql-tools/wrap": "^10.0.5",
    "@nestjs-query/core": "^0.30.0",
    "@ngrx/effects": "^18.0.1",
    "@ngrx/entity": "^18.0.1",
    "@ngrx/operators": "^18.0.0",
    "@ngrx/router-store": "^18.0.1",
    "@ngrx/store": "^18.0.1",
    "@ngrx/store-devtools": "^18.0.1",
    "@ngx-formly/core": "^6.3.0",
    "@ngx-formly/ng-zorro-antd": "^6.3.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@popperjs/core": "^2.11.8",
    "apollo-angular": "^7.0.2",
    "apollo-link-scalars": "^4.0.3",
    "apollo-link-ws": "^1.0.20",
    "d3-drag": "^3.0.0",
    "d3-selection": "^3.0.0",
    "d3-shape": "^3.2.0",
    "d3-transition": "^3.0.1",
    "d3-zoom": "^3.0.0",
    "dagre": "^0.8.5",
    "dagre-compound": "^0.0.13",
    "date-fns": "^2.30.0",
    "echarts": "^5.2.2",
    "fabric": "5.3.0-browser",
    "file-saver": "^2.0.5",
    "graphql": "^16.9.0",
    "graphql-scalars": "^1.23.0",
    "graphql-ws": "^5.15.0",
    "ibantools": "^4.5.1",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "monaco-editor": "^0.33.0",
    "ng-alain": "^18.0.2",
    "ng-lazyload-image": "^9.1.3",
    "ng-zorro-antd": "^18.0.1",
    "ngx-echarts": "^18.0.0",
    "ngx-json-viewer": "^3.2.1",
    "ngx-markdown": "^18.0.0",
    "rxjs": "~7.8.0",
    "screenfull": "^6.0.2",
    "svg-pan-zoom": "^3.6.1",
    "tesseract.js": "^5.1.0",
    "ts-node": "^10.9.1",
    "ts-pattern": "^5.2.0",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "zone.js": "^0.14.6"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.1.0",
    "@angular-eslint/builder": "^18.0.1",
    "@angular-eslint/eslint-plugin": "^18.0.0",
    "@angular-eslint/eslint-plugin-template": "^18.0.0",
    "@angular-eslint/schematics": "^18.0.0",
    "@angular-eslint/template-parser": "^18.0.0",
    "@angular/cli": "^18.1.0",
    "@angular/compiler-cli": "^18.1.0",
    "@angular/language-service": "^18.0.0",
    "@compodoc/compodoc": "^1.1.23",
    "@delon/testing": "^18.0.2",
    "@graphql-codegen/cli": "^5.0.2",
    "@graphql-codegen/typescript": "^4.0.9",
    "@graphql-codegen/typescript-apollo-angular": "^4.0.0",
    "@graphql-codegen/typescript-operations": "^4.2.3",
    "@ngrx/eslint-plugin": "^18.0.1",
    "@ngx-formly/schematics": "^6.3.0",
    "@storybook/addon-a11y": "^8.2.4",
    "@storybook/addon-docs": "^8.2.4",
    "@storybook/addon-essentials": "^8.2.4",
    "@storybook/addon-interactions": "^8.2.4",
    "@storybook/addon-links": "^8.2.4",
    "@storybook/angular": "^8.2.4",
    "@storybook/blocks": "^8.2.4",
    "@storybook/test": "^8.2.4",
    "@types/d3": "^7.4.3",
    "@types/dompurify": "^3.0.2",
    "@types/fabric": "^5.3.7",
    "@types/file-saver": "^2.0.7",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.7",
    "@types/node": "^20.14.11",
    "@types/offscreencanvas": "^2019.7.3",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/eslint-plugin": "7.3.1",
    "@typescript-eslint/parser": "7.3.1",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "~9.1.0",
    "eslint-plugin-deprecation": "^3.0.0",
    "eslint-plugin-import": "~2.29.1",
    "eslint-plugin-jsdoc": "~48.7.0",
    "eslint-plugin-prefer-arrow": "~1.2.2",
    "eslint-plugin-prettier": "~5.1.3",
    "eslint-plugin-sonarjs": "^1.0.3",
    "eslint-plugin-storybook": "^0.8.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-alain": "^18.0.2",
    "ng-alain-plugin-theme": "^18.0.0",
    "prettier": "^3.3.2",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "source-map-explorer": "^2.5.3",
    "storybook": "^8.2.4",
    "stylelint": "^16.3.1",
    "stylelint-config-rational-order": "^0.1.2",
    "stylelint-config-standard": "^36.0.0",
    "stylelint-declaration-block-no-ignored-properties": "^2.8.0",
    "stylelint-order": "^6.0.4",
    "typescript": "5.4.5"
  },
  "lint-staged": {
    "(src)/**/*.{html,ts}": [
      "eslint --fix"
    ],
    "(src)/**/*.less": [
      "npm run lint:style"
    ]
  }
}
