export type CustomIconDefinition = {
  type: string;
  literal: string;
};

export const ICONS_CUSTOM: CustomIconDefinition[] = [
  {
    type: 'lector:angle-down', // fa-regular fa-angle-down
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M360.5 217.5l-152 143.1C203.9 365.8 197.9 368 192 368s-11.88-2.188-16.5-6.562L23.5 217.5C13.87 208.3 13.47 193.1 22.56 183.5C31.69 173.8 46.94 173.5 56.5 182.6L192 310.9l135.5-128.4c9.562-9.094 24.75-8.75 33.94 .9375C370.5 193.1 370.1 208.3 360.5 217.5z"/></svg>'
  },
  {
    type: 'lector:angle-up', // fa-regular fa-angle-up
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M23.5 294.5l152-143.1C180.1 146.2 186.1 144 192 144s11.88 2.188 16.5 6.562l152 143.1c9.625 9.125 10.03 24.31 .9375 33.93c-9.125 9.688-24.38 10.03-33.94 .9375l-135.5-128.4l-135.5 128.4c-9.562 9.094-24.75 8.75-33.94-.9375C13.47 318.9 13.87 303.7 23.5 294.5z"/></svg>'
  },
  {
    type: 'lector:angles-up', // fa-regular fa-angles-up
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M55.1 241.9L192 120.2l136 121.6c4.563 4.094 10.28 6.125 16 6.125c6.594 0 13.16-2.687 17.88-7.1c8.844-9.874 8-25.06-1.875-33.87L208 70.14c-9.125-8.187-22.88-8.187-32 0L23.99 206.1C14.12 214.9 13.28 230.1 22.12 240C30.93 249.9 46.15 250.7 55.1 241.9zM208 262.1c-9.125-8.187-22.88-8.187-32 0l-152 135.1c-9.875 8.812-10.72 23.1-1.875 33.87c8.813 9.906 24.03 10.72 33.88 1.875L192 312.2l136 121.6c4.563 4.094 10.28 6.125 16 6.125c6.594 0 13.16-2.687 17.88-7.1c8.844-9.874 8-25.06-1.875-33.87L208 262.1z"/></svg>'
  },
  {
    type: 'lector:input-text', // fa-duotone fa-input-text
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 32C93.82 32 118.9 52.4 125.1 80H322C329.1 52.4 354.2 32 384 32C419.3 32 448 60.65 448 96C448 125.8 427.6 150.9 400 157.1V354C427.6 361.1 448 386.2 448 416C448 451.3 419.3 480 384 480C354.2 480 329.1 459.6 322 432H125.1C118.9 459.6 93.82 480 64 480C28.65 480 0 451.3 0 416C0 386.2 20.4 361.1 48 354V157.1C20.4 150.9 0 125.8 0 96C0 60.65 28.65 32 64 32V32zM64 128C81.67 128 96 113.7 96 96C96 78.33 81.67 64 64 64C46.33 64 32 78.33 32 96C32 113.7 46.33 128 64 128zM125.1 400H322C327.8 377.5 345.5 359.8 368 354V157.1C345.5 152.2 327.8 134.5 322 112H125.1C120.2 134.5 102.5 152.2 80 157.1V354C102.5 359.8 120.2 377.5 125.1 400V400zM64 384C46.33 384 32 398.3 32 416C32 433.7 46.33 448 64 448C81.67 448 96 433.7 96 416C96 398.3 81.67 384 64 384zM352 416C352 433.7 366.3 448 384 448C401.7 448 416 433.7 416 416C416 398.3 401.7 384 384 384C366.3 384 352 398.3 352 416zM416 96C416 78.33 401.7 64 384 64C366.3 64 352 78.33 352 96C352 113.7 366.3 128 384 128C401.7 128 416 113.7 416 96z"/></svg>'
  },
  {
    type: 'lector:keyboard', // fa-regular fa-keyboard
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 112c-8.8 0-16 7.2-16 16V384c0 8.8 7.2 16 16 16H512c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H64zM0 128C0 92.7 28.7 64 64 64H512c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM176 320H400c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm-72-72c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H120c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H200c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H280c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H360c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16zm64 96c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V248zm16-96h16c8.8 0 16 7.2 16 16v16c0 8.8-7.2 16-16 16H440c-8.8 0-16-7.2-16-16V168c0-8.8 7.2-16 16-16z"/></svg>'
  },
  {
    type: 'lector:list-tree', // fa-thin fa-list-tree
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M32 112H64V80H32v32zM0 72C0 58.7 10.7 48 24 48H72c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H64v96h64v-8c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H152c-13.3 0-24-10.7-24-24v-8H64V384c0 8.8 7.2 16 16 16h48v-8c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H152c-13.3 0-24-10.7-24-24v-8H80c-26.5 0-48-21.5-48-48V256 144H24c-13.3 0-24-10.7-24-24V72zM160 96c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H176c-8.8 0-16-7.2-16-16zM288 256c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H304c-8.8 0-16-7.2-16-16zm0 160c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H304c-8.8 0-16-7.2-16-16zM160 432h32V400H160v32zm0-192v32h32V240H160z"/></svg>'
  },
  {
    type: 'lector:snooze', // fa-light fa-snooze
    literal:
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M176 32h92.37L164 149.4C159.8 154.1 158.8 160.8 161.4 166.6C164 172.3 169.7 176 176 176h127.1c8.844 0 15.1-7.156 15.1-16s-7.155-16-15.1-16H211.6l104.3-117.4c4.187-4.719 5.219-11.44 2.625-17.19S310.3 0 303.1 0H176C167.2 0 160 7.156 160 16S167.2 32 176 32zM431.1 400h-94.72l107.2-134c3.844-4.812 4.594-11.38 1.906-16.94C443.7 243.5 438.2 240 431.1 240h-127.1c-8.844 0-16 7.156-16 16s7.157 16 16 16h94.72l-107.2 134c-3.844 4.812-4.594 11.38-1.906 16.94C292.2 428.5 297.8 432 303.1 432h127.1c8.844 0 15.1-7.156 15.1-16S440.8 400 431.1 400zM208 240h-159.1C39.16 240 32 247.2 32 256s7.156 16 15.1 16h126.7l-171.2 214c-3.844 4.812-4.594 11.38-1.906 16.94C4.253 508.5 9.847 512 16 512h175.1c8.844 0 15.1-7.156 15.1-16S200.8 480 192 480h-142.7l171.2-214c3.844-4.812 4.594-11.38 1.906-16.94C219.8 243.5 214.2 240 208 240z"/></svg>'
  }
];
